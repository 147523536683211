// @ts-strict-ignore
import A11yDialog from 'a11y-dialog';
import groupBy from 'lodash/groupBy';
import kebabCase from 'lodash/kebabCase';

import { GetCurrentTool, SetCurrentTool } from 'controllers/doc_editor_controller';
import DocEditorSingleStore, { getGroupColumns }
  from 'src/chux/doc_editor/single_store';
import EditableFieldsCollection from 'src/chux/editable_fields/collection';
import { getFields, updateFields }
  from 'src/chux/editable_fields/store';
import DocPage from 'src/doc_editor/doc_page';
import FieldComponent from 'src/doc_editor/field';
import InputField from 'src/doc_editor/fields/input';
import FieldModel from 'src/doc_editor/fields/models/field';
import FieldModels from 'src/doc_editor/fields/models/index';
import isFieldResizable from 'src/doc_editor/helpers/is_field_resizable';
import StampPreview from 'src/doc_editor/stamp_preview';
import { assert } from 'src/helpers/assertion';
import { findEl } from 'src/helpers/finders';
import { setContext } from 'src/helpers/notify';
import { events, publish, subscribe } from 'src/helpers/pub_sub';

function boundAddFields(payload): void {
  EditableFieldsCollection.add(payload);
}

type DocParams = {
  getCurrentTool: GetCurrentTool;
  steps: Step[] | null;
  setCurrentTool: SetCurrentTool;
  testing: boolean;
};

type PageClickCoords = { xPos: number; yPos: number };
type BeginPlacingField = { page: DocPage; pageClickCoords: PageClickCoords };

class Doc {
  $element: JQuery;
  element: HTMLElement;
  activeField: FieldComponent;
  fontSize: number;
  params: DocParams;
  isResizing: boolean;
  pages: DocPage[];
  initialLoad: boolean;
  lastClickX: number | null;
  lastClickY: number | null;
  loadingDialog: A11yDialog;
  stampPreview: StampPreview;

  constructor(element: HTMLElement, params: DocParams) {
    this.$element = $(element);
    this.element = element;

    this.pages = [];
    this.params = params;
    this.activeField = null;

    this.isResizing = false;
    this.lastClickX = null;
    this.lastClickY = null;

    this.initialLoad = true;

    this.copyFields = this.copyFields.bind(this);
    this.refreshCurrentTool = this.refreshCurrentTool.bind(this);
    this.setActiveFields = this.setActiveFields.bind(this);

    this.stampPreview = new StampPreview(
      findEl(this.element, 'div', '#stamp-preview'),
      { steps: this.params.steps },
    );

    setContext({ docId: this.docIdValue });

    const $loadingModal = $('.doc-editor-loading-modal');

    if ($loadingModal[0]) {
      this.loadingDialog = new A11yDialog($loadingModal[0]);
      this.loadingDialog.show();
    }

    this.fontSize = this.$element.data('font-size');

    this.createPages();

    this.loadFields();

    subscribe(events.PAGE_MOUSEDOWN, this.beginPlacingField.bind(this));
    subscribe(events.PAGE_MOUSEUP, this.finishPlacingField.bind(this));

    this.$element.find('.page').on('mousemove', (event) => {
      this.moveStamp(event);
      this.resizeFieldOnDrag(event);
    }).on('mouseout', () => { this.stampPreview.hide(); });
  }

  get dataSourceValue(): DataSource {
    return JSON.parse(this.element.dataset.dataSourceValue);
  }

  get docIdValue(): number {
    return this.$element.data('id');
  }

  refreshCurrentTool(): void {
    const currentTool = this.params.getCurrentTool();

    this.stampPreview.setStamp(currentTool, this.fontSize);
    this.updateCursor(currentTool);
  }

  reset(): void {
    this.stampPreview.hide();
    this.params.setCurrentTool(null);
    this.removeInvalidFields();
  }

  updateCursor(tool: string): void {
    this.$element.removeClass((_index, className) => {
      const matchingClasses = className.match(/\S+-field-cursor/ug);

      return matchingClasses ? matchingClasses.join(' ') : '';
    });

    if (tool) { this.$element.addClass(`${kebabCase(tool)}-field-cursor`); }
  }

  createPages(): void {
    Array.from(this.$element.find('.page')).forEach((page, pageNum) => {
      const payload = {
        dataSource: this.dataSourceValue,
        getCurrentTool: this.params.getCurrentTool,
        getFields,
        getGroupColumns,
        pageNum,
        setActiveFields: this.setActiveFields,
        steps: this.params.steps,
        store: DocEditorSingleStore,
        updateDocEditor: DocEditorSingleStore.update,
        updateFields,
      };

      this.pages.push(new DocPage(page, payload));
    });
  }

  clearFields(): void {
    this.pages.forEach((page) => {
      page.clearFields();
    });
  }

  loadFields(): void {
    const payload = {
      docId: this.docIdValue,
      opts: {
        success: (): void => {
          this.createFields(EditableFieldsCollection.models);
          if (this.initialLoad) {
            this.storeInitialFieldsData();
            this.assignMissingFieldStepIds();
            this.initialLoad = false;
          }
        },
      },
    };

    EditableFieldsCollection.fetchFields(payload);
  }

  getPageFields(): FieldComponent[] {
    return this.pages.flatMap((page) => { return page.fields; });
  }

  storeInitialFieldsData(): void {
    if (this.loadingDialog) {
      this.loadingDialog.hide();
    }
    const payload = {
      allFieldsLoaded: true,
      fieldsAtLastSave: getFields(),
    };

    DocEditorSingleStore.update(payload);
  }

  assignMissingFieldStepIds(): void {
    const { steps } = this.params;

    if (!steps) { return; } // null when non-routed campaign

    const fields: Field[] = Object.values(getFields());
    const fieldsMissingStepId = fields.filter((field: Field) => {
      return field.stepId === null;
    });

    const stepId = steps[0].id;
    const payload = fieldsMissingStepId.map((field: Field) => {
      return { number: field.number, stepId };
    });

    updateFields(payload);
  }

  copyFields(docId: number): void {
    DocEditorSingleStore.update({ allFieldsLoaded: false });
    this.clearFields();
    const { steps } = this.params;

    const payload = {
      docId,
      opts: {
        success: (): void => {
          const fieldsPayload = { stepId: steps ? steps[0].id : null };

          EditableFieldsCollection.updateAll(fieldsPayload);
          this.createFields(EditableFieldsCollection.models);
          DocEditorSingleStore.update({ allFieldsLoaded: true });
        },
      },
    };

    EditableFieldsCollection.fetchFields(payload);
  }

  duplicateFields(fields: Field[]): FieldModel[] {
    const fieldsToDuplicate = fields.filter((field) => {
      return field.type !== 'ReferenceNumber';
    });
    const newFieldsAttributes = fieldsToDuplicate.map((field) => {
      return { ...field, label: null, number: null };
    });

    const newFieldsModels =
      EditableFieldsCollection.add(newFieldsAttributes);

    this.createFields(newFieldsModels);

    return newFieldsModels;
  }

  setActiveFields(fieldNumbers: Set<number>): void {
    const storeFields = getFields();
    const selectedFieldsPayload =
      Object.values(storeFields).map((field: Field) => {
        return { number: field.number, selected: fieldNumbers.has(field.number) };
      });

    DocEditorSingleStore.update({ activeNumbers: fieldNumbers });
    updateFields(selectedFieldsPayload);
  }

  createFields(fieldModels: FieldModel[]): void {
    const fieldModelsByPageNum = groupBy(fieldModels, (fieldModel) => {
      return fieldModel.get('pageNum');
    });

    Object.entries(fieldModelsByPageNum)
      .forEach(([pageNum, pageFieldModels]) => {
        const page = this.pages[Number(pageNum) - 1];

        if (page) { page.loadFields(pageFieldModels); }
      });
  }

  moveStamp(event: JQuery.MouseMoveEvent): void {
    const mouseOverPage = event.target.className.includes('page');
    const currentTool = this.params.getCurrentTool();
    const offset = assert(this.$element.offset());

    if (mouseOverPage && currentTool && !this.isResizing) {
      this.stampPreview.setPosition(
        event.pageX - offset.left,
        event.pageY - offset.top,
      );
      this.stampPreview.show();
    } else {
      this.stampPreview.hide();
    }
  }

  resizeFieldOnDrag(event: JQuery.MouseMoveEvent): void {
    if (this.isResizing && this.activeField instanceof InputField) {
      const { lastClickX, lastClickY } = this;
      const fieldConfig = this.activeField.config;

      const height = Math.max(
        event.pageY - assert(lastClickY) - fieldConfig.dragToleranceY,
        this.activeField.getMinHeight(),
      );
      const width = Math.max(
        event.pageX - assert(lastClickX) - fieldConfig.dragToleranceX,
        this.activeField.getMinWidth(),
      );

      this.activeField.setFieldSize({ height, width });
    }
  }

  beginPlacingField(
    { page, pageClickCoords }:
    { page: DocPage; pageClickCoords: PageClickCoords },
  ): void {
    const currentTool = this.params.getCurrentTool();

    this.removeInvalidFields();
    if (!currentTool || !page) { return; }

    const pageOffset = page.$element.offset();
    const { steps } = this.params;
    const stepId = steps && steps[0].id;

    const payload = new FieldModels[currentTool]({
      fontSize: this.fontSize,
      height: this.stampPreview.height(),
      stepId,
      testing: this.params.testing,
      type: currentTool,
      width: this.stampPreview.width(),
      xPos: this.stampPreview.xPos() - pageOffset.left,
      yPos: this.stampPreview.yPos() - pageOffset.top,
    });

    if (currentTool === 'Prefill') {
      payload.set({ columns: getGroupColumns() });
    }

    boundAddFields(payload);

    this.activeField = page.newField(payload);
    this.stampPreview.hide();
    publish(events.FIELD_CHANGED);

    if (this.isActiveFieldResizable()) {
      this.startResizing(pageClickCoords);
    }
  }

  isActiveFieldResizable(): boolean {
    const field = this.activeField;

    return field && isFieldResizable(field.model.toJSON());
  }

  finishPlacingField(): void {
    this.stopResizing();
    const field = this.activeField;

    if (field) {
      field.focus();
      this.activeField = null;
    }

    if (this.params.getCurrentTool() === 'ReferenceNumber') { this.reset(); }
  }

  startResizing(pageClickCoords: PageClickCoords): void {
    this.isResizing = true;
    $('body').addClass('no-selecting');
    const field = this.activeField;

    this.lastClickX = pageClickCoords.xPos - (field.$element.width() / 2);
    this.lastClickY = pageClickCoords.yPos - (field.$element.height() / 2);
  }

  stopResizing(): void {
    this.isResizing = false;
    $('body').removeClass('no-selecting');
  }

  setFontSize(val: number): void {
    this.fontSize = val;
    this.setFontSizeOnPages();
    this.stampPreview.setFontSize(this.fontSize);
  }

  setFontSizeOnPages(): void {
    this.getPageFields().forEach((field) => {
      field.setFontSize(this.fontSize);
    });
  }

  removeInvalidFields(): void {
    const invalidFields = Object.values(getFields())
      .filter((field) => { return !field.isValid; });

    invalidFields.forEach((field) => {
      EditableFieldsCollection.remove(field.number);
    });
  }

  getPages(): DocPage[] {
    return this.pages;
  }

  aggregateFieldParams(): SerializedField[] {
    const validFields = this.getPageFields().filter((field) => {
      return field.isValid() && field.$element.is(':visible');
    });

    return validFields.map((field) => {
      return field.serialize();
    });
  }

  getSubmitParams(): { docId: number; fields: SerializedField[] } {
    return {
      docId: this.docIdValue,
      fields: this.aggregateFieldParams(),
    };
  }
}

export default Doc;

export type SetActiveFields = Doc['setActiveFields'];
export type { BeginPlacingField };
