// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Subscription } from '@rails/actioncable';
import Rails from '@rails/ujs';
import { v4 as uuidv4 } from 'uuid';

import consumer from 'src/channels/consumer';
import { assert } from 'src/helpers/assertion';
import { captureUJSEvents } from 'src/helpers/event';
import Location from 'src/helpers/location';

export default class extends Controller {
  static targets = ['downloadLink', 'form', 'submitButton'];

  downloadLinkTarget: HTMLElement;
  formTarget: HTMLElement;
  submitButtonTarget: HTMLInputElement;

  hasDownloadLinkTarget: boolean;

  ajaxPromise: Promise<string>;
  resolve: (value: string) => void;
  subscription: Subscription;

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  connect(): void {
    this.submitButtonTarget.disabled = true;
    this.subscription = consumer.subscriptions.create(
      { channel: 'JobsChannel', identifier: uuidv4() },
      {
        connected: this.subscriptionConnected.bind(this),
        received: this.messageReceived.bind(this),
      },
    );

    this.ajaxPromise = new Promise((resolve) => { this.resolve = resolve; });

    captureUJSEvents(this.formTarget, (event) => {
      const { jobId } = event.detail[0].data;

      assert(jobId);
      this.resolve(jobId);
    });
  }

  subscriptionConnected(): void {
    this.submitButtonTarget.disabled = false;
  }

  downloadPending(): void {
    if (this.hasDownloadLinkTarget) {
      this.downloadLinkTarget.textContent = 'In progress...';
    }
  }

  async messageReceived(data): Promise<void> {
    const jobId = await this.ajaxPromise;

    assert(jobId);
    if (data.jobId === jobId) {
      const { downloadPath, jobResultPath } = data;

      if (downloadPath) {
        this.download(downloadPath, jobResultPath);
      } else {
        Location.navigateTo(jobResultPath);
      }
    }
  }

  download(downloadPath: string, jobResultPath: string): void {
    if (this.hasDownloadLinkTarget) {
      this.downloadLinkTarget.innerHTML = '';
      const link = document.createElement('a');
      link.href = downloadPath;
      link.rel = 'noopener noreferrer';
      link.target = '_blank';
      link.textContent = 'Click here to download';
      this.downloadLinkTarget.appendChild(link);
    } else {
      Location.downloadFileAndRedirect(downloadPath, jobResultPath);
    }

    this.reset();
  }

  reset(): void {
    this.ajaxPromise = new Promise((resolve) => { this.resolve = resolve; });
    this.submitButtonTarget.disabled = false;
    Rails.enableElement(this.submitButtonTarget);

    document.querySelectorAll(
      `button[form="${this.formTarget.id}"]`,
    ).forEach((button) => {
      (button as HTMLButtonElement).disabled = false;
    });
  }
}
