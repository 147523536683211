import { IconName } from 'src/common/components/icon';
import grab from 'src/helpers/grab';

const NAME_MAP = {
  archive: 'fa-solid fa-box-archive',
  arrowLeft: 'fa-solid fa-arrow-left',
  arrowRight: 'fa-solid fa-arrow-right',
  arrowUpFromBracket: 'fa-solid fa-arrow-up-from-bracket',
  ban: 'fa-solid fa-ban',
  caretDown: 'fa-solid fa-caret-down',
  caretLeft: 'fa-solid fa-caret-left',
  caretRight: 'fa-solid fa-caret-right',
  caretUp: 'fa-solid fa-caret-up',
  check: 'fa-solid fa-check',
  checkCircle: 'fa-solid fa-circle-check',
  chevronDown: 'fa-solid fa-chevron-down',
  chevronUp: 'fa-solid fa-chevron-up',
  circleNotch: 'fas fa-circle-notch',
  clockO: 'fa-regular fa-clock-o',
  close: 'fa-solid fa-times',
  download: 'fa-solid fa-download',
  ellipsisHorizontal: 'fa-solid fa-ellipsis',
  ellipsisVertical: 'fa-solid fa-ellipsis-vertical',
  exclamationCircle: 'fa-solid fa-circle-exclamation',
  exclamationTriangle: 'fa-solid fa-triangle-exclamation',
  fileText: 'fa-solid fa-file-lines',
  folder: 'fa-solid fa-folder',
  heart: 'fa-solid fa-heart',
  infoCircle: 'fa-solid fa-circle-info',
  paperclip: 'fa-solid fa-paperclip',
  pencil: 'fa-solid fa-pencil',
  plus: 'fa-solid fa-plus',
  refresh: 'fa-solid fa-sync-alt',
  repeat: 'fa-solid fa-redo',
  reply: 'fa-solid fa-reply',
  rocket: 'fa-solid fa-rocket',
  search: 'fa-solid fa-magnifying-glass',
  send: 'fa-solid fa-paper-plane',
  share: 'fa-solid fa-share',
  shareAlt: 'fa-solid fa-share-alt',
  spinner: 'fa-solid fa-spinner',
  times: 'fa-solid fa-xmark',
  timesCircle: 'fa-solid fa-times-circle',
  trash: 'fa-solid fa-trash-can',
  userPlus: 'fa-solid fa-user-plus',
  warning: 'fa-solid fa-exclamation-triangle',
};

function iconClasses(name: IconName): string {
  return grab(NAME_MAP, name);
}

function iconClassName(name: IconName): string {
  const classes = iconClasses(name);
  const [, className] = classes.split(' ');
  return className;
}

export { iconClassName };
export default iconClasses;
