import React, { ReactNode } from 'react';

import FlashesContainer from 'src/flashes/containers/flashes';
import ResponsesSearchPaginate from 'src/responses/components/search_paginate';
import ResponsesBulkActionsContainer from 'src/responses/containers/bulk_actions';
import ResponsesFiltersContainer from 'src/responses/containers/filters';

type Props = {
  campaign: Campaign;
  formGroup: FormGroup;
  headers: Responses.TableHeader[];
  userFormGroupDescriptions: UserFormGroupDescriptions;
};

class ResponsesActions extends React.PureComponent<Props, never> {
  render(): ReactNode {
    const { campaign, headers } = this.props;

    return (
      <div className='responses-actions'>
        <div className='row row-flashes'>
          <div className='col-sm-8 col-sm-offset-2'><FlashesContainer /></div>
        </div>
        {this.actionBarComponent()}
        <ResponsesFiltersContainer campaign={campaign} headers={headers} />
      </div>
    );
  }

  actionBarComponent(): React.JSX.Element {
    const {
      campaign,
      formGroup,
      userFormGroupDescriptions,
    } = this.props;

    return (
      <React.Fragment>
        <ResponsesBulkActionsContainer campaign={campaign} />
        <ResponsesSearchPaginate
          formGroup={formGroup}
          userFormGroupDescriptions={userFormGroupDescriptions}
        />
      </React.Fragment>
    );
  }
}

export default ResponsesActions;
