// @ts-strict-ignore
import AttachmentField from './fields/attachment';

import CheckboxField from 'src/form_filler/fields/checkbox';
import CommonField from 'src/form_filler/fields/common';
import DateField from 'src/form_filler/fields/date';
import DropdownField from 'src/form_filler/fields/dropdown';
import FormulaField from 'src/form_filler/fields/formula';
import ReferenceNumberField from 'src/form_filler/fields/reference_number';
import SignatureField from 'src/form_filler/fields/signature';
import TextField from 'src/form_filler/fields/text';
import TimeField from 'src/form_filler/fields/time';
import UserDataField from 'src/form_filler/fields/user_data';

const FieldTypes = {
  AttachmentField,
  CheckboxField,
  CommonField,
  DateField,
  DropdownField,
  FormulaField,
  ReferenceNumberField,
  SignatureField,
  TextField,
  TimeField,
  UserDataField,
};

function buildFieldsCollection() {
  return Array.from($('.field-with-data')).map((fieldEl) => {
    return createField(fieldEl);
  });
}

// private

function createField(fieldEl: HTMLElement) {
  const $field = $(fieldEl);
  const fieldData = $field.data('field');

  return new FieldTypes[fieldData.type](fieldEl, { ...fieldData });
}

export default buildFieldsCollection;
