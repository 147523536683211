// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import 'src/form_filler/drawn_signatures';

import FieldRule, { SerializedFieldRule } from 'src/form_filler/field_rule';
import FormFiller from 'src/form_filler/form_filler';
import Location from 'src/helpers/location';

class FormFillerController extends Controller<HTMLElement> {
  static values = {
    rules: Array,
  };

  rulesValue: SerializedFieldRule[];
  fieldRuleArray: FieldRule[];
  formFiller: FormFiller;

  get fieldRules(): FieldRule[] {
    this.fieldRuleArray ||= this.rulesValue.map((rule) => {
      return new FieldRule(rule);
    });
    return this.fieldRuleArray;
  }

  connect(): void {
    this.formFiller = new FormFiller(this.element);
    this.applyRules();
  }

  toggleRules(event: DOMEvent): void {
    const trigger = parseInt(event.target.dataset.triggerNumber, 10);

    const rules = this.fieldRules
      .filter((rule) => { return rule.triggerFieldNumber === trigger; });

    if (!rules) { return; }

    rules.forEach((rule) => { rule.toggle(); });
    this.applyRules();
  }

  applyRules(): void {
    this.fieldRules
      .sort((ruleA, ruleB) => { return ruleA.order() - ruleB.order(); })
      .forEach((rule) => { rule.apply(); });

    if (this.element.dataset.completed) { return; }

    this.formFiller.onChangeRefreshFormulas();
  }

  disconnect(): void {
    Location.removeBeforeUnload('form-filler');
  }
}

export default FormFillerController;
