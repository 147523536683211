// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import zip from 'lodash/zip';

import { fetchGet } from 'src/helpers/fetch';
import { findEls } from 'src/helpers/finders';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import validateEmail from 'src/helpers/validate_email';
import { hide, show } from 'src/helpers/visibility';

type EventParams = { clientType: ClientStepType; stepCid: number };
type ChangeStepTypeActionEvent = ActionEvent<EventParams>;

class StepRowController extends Controller {
  static targets = [
    'additionalOptionsSelection',
    'approverListAdderSection',
    'approverListContainer',
    'approverListRecipients',
    'approverListSelect',
    'approverListStepInput',
    'bulkRecipientEmails',
    'bulkRecipientNames',
    'hiddenStepTypeInput',
    'newRecipientRowTemplate',
    'noApproverListError',
    'numApproversText',
    'promptStepInput',
    'recipientAdderSection',
    'recipientInput',
    'recipientsContainer',
    'recipientStepInput',
    'recipientsSelection',
    'recipientsSelectionSection',
    'removeRecipientButton',
    'showAdvancedOptionsButton',
    'showStepSelectionsButton',
    'skippable',
    'skippableSelection',
    'specialApprover',
    'specialApproverSelection',
    'stepAdvancedOptions',
    'stepLabelSelection',
    'stepLabel',
    'stepSelections',
    'stepTypeSelection',
  ];

  static values = {
    step: Object,
  };

  hasApproverListStepInputTarget: boolean;

  additionalOptionsSelectionTarget: HTMLDivElement;
  approverListAdderSectionTarget: HTMLDivElement;
  approverListContainerTarget: HTMLTableSectionElement;
  approverListRecipientsTarget: HTMLDivElement;
  approverListSelectTarget: HTMLSelectElement;
  approverListStepInputTarget: HTMLInputElement;
  bulkRecipientEmailsTarget: HTMLTextAreaElement;
  bulkRecipientNamesTarget: HTMLTextAreaElement;
  hiddenStepTypeInputTarget: HTMLInputElement;
  newRecipientRowTemplateTarget: HTMLTemplateElement;
  noApproverListErrorTarget: HTMLParagraphElement;
  numApproversTextTarget: HTMLSpanElement;
  promptStepInputTarget: HTMLInputElement;
  recipientAdderSectionTarget: HTMLDivElement;
  recipientsContainerTarget: HTMLTableSectionElement;
  recipientInputTargets: HTMLTableRowElement[];
  recipientStepInputTarget: HTMLInputElement;
  recipientsSelectionTarget: HTMLUListElement;
  recipientsSelectionSectionTarget: HTMLDivElement;
  removeRecipientButtonTargets: HTMLButtonElement[];
  showAdvancedOptionsButtonTarget: HTMLButtonElement;
  showStepSelectionsButtonTarget: HTMLButtonElement;
  skippableTarget: HTMLInputElement;
  skippableSelectionTarget: HTMLLIElement;
  specialApproverTarget: HTMLInputElement;
  specialApproverSelectionTarget: HTMLLIElement;
  stepAdvancedOptionsTarget: HTMLDivElement;
  stepLabelSelectionTarget: HTMLDivElement;
  stepLabelTarget: HTMLInputElement;
  stepSelectionsTarget: HTMLDivElement;
  stepTypeSelectionTarget: HTMLDivElement;

  stepValue: { cid: string };

  connect(): void {
    this.renderAdvancedOptions();

    this.changeStepTypeForStep(this.stepValue.cid, 'RecipientStep');
  }

  renderStepSelections(): void {
    const stepType = this.stepType();

    this.stepLabelSelectionTarget.textContent = this.stepLabelTarget.value || '--';
    this.stepTypeSelectionTarget.textContent = stepType;

    this.displayAdditionalOptionSelections();

    this.displayStepTypeRecipientsSelection();

    show(this.stepSelectionsTarget);
    show(this.showAdvancedOptionsButtonTarget);
    hide(this.stepAdvancedOptionsTarget);
    hide(this.showStepSelectionsButtonTarget);
  }

  displayAdditionalOptionSelections(): void {
    const skippable = this.skippableTarget.checked;
    const specialApprover = this.specialApproverTarget.checked;

    if (skippable || specialApprover) {
      show(this.additionalOptionsSelectionTarget);

      this.displaySkippableSelection();
      this.displaySpecialApproverSelection();
    } else {
      hide(this.additionalOptionsSelectionTarget);
    }
  }

  displaySkippableSelection(): void {
    if (this.skippableTarget.checked) {
      show(this.skippableSelectionTarget);
    } else {
      hide(this.skippableSelectionTarget);
    }
  }

  displaySpecialApproverSelection(): void {
    if (this.specialApproverTarget.checked) {
      show(this.specialApproverSelectionTarget);
    } else {
      hide(this.specialApproverSelectionTarget);
    }
  }

  displayStepTypeRecipientsSelection(): void {
    if (this.promptStepInputTarget.checked) {
      hide(this.recipientsSelectionSectionTarget);
    } else {
      this.recipientsSelectionTarget.innerHTML = '';
      show(this.recipientsSelectionSectionTarget);
      hide(this.noApproverListErrorTarget);

      this.displayRecipientsSelection();
    }
  }

  displayRecipientsSelection(): void {
    const approverListStepSelected = this.hasApproverListStepInputTarget &&
      this.approverListStepInputTarget.checked;

    if (approverListStepSelected) {
      if (this.approverListSelectTarget.value) {
        this.approverListRecipients().forEach((recipient) => {
          this.recipientsSelectionTarget.appendChild(recipient);
        });
        show(this.recipientsSelectionTarget);
      } else {
        show(this.noApproverListErrorTarget);
        hide(this.recipientsSelectionTarget);
      }
    } else {
      this.recipients().forEach((recipient) => {
        this.recipientsSelectionTarget.appendChild(recipient);
      });
      show(this.recipientsSelectionTarget);
    }
  }

  recipients(): HTMLLIElement[] {
    const recipientRows = this.recipientsContainerTarget.rows;

    return Array.from(recipientRows).map((row) => {
      const [nameInput, emailInput] = row.querySelectorAll('input');
      const name = nameInput?.value || '';
      const email = emailInput?.value || '';
      const element = document.createElement('li');

      if (Boolean(name) && validateEmail(email)) {
        element.textContent = `${name} <${email}>`;
      } else {
        element.className = 'text-danger';
        element.textContent = 'Invalid recipient input';
      }

      return element;
    });
  }

  approverListRecipients(): HTMLLIElement[] {
    const recipientRows = this.approverListContainerTarget.rows;

    return Array.from(recipientRows).map((row) => {
      const [nameTd, emailTd] = row.querySelectorAll('td');
      const name = nameTd?.textContent || '';
      const email = emailTd?.textContent || '';
      const element = document.createElement('li');

      element.textContent = `${name} <${email}>`;

      return element;
    });
  }

  renderAdvancedOptions(): void {
    show(this.stepAdvancedOptionsTarget);
    show(this.showStepSelectionsButtonTarget);
    hide(this.stepSelectionsTarget);
    hide(this.showAdvancedOptionsButtonTarget);
  }

  stepType(): string {
    return this.promptStepInputTarget.checked ? 'Prompt Step' : 'Recipient Step';
  }

  bulkAddRecipients(event: ActionEvent<{ stepCid: number }>): void {
    const stepCid = event.params.stepCid.toString();

    const names = this.bulkRecipientNamesTarget.value.split('\n');
    const emails = this.bulkRecipientEmailsTarget.value.split('\n');

    zip(names, emails).forEach((recipient) => {
      this.addRecipientToStep(stepCid, recipient[0], recipient[1]);
    });

    this.bulkRecipientNamesTarget.value = '';
    this.bulkRecipientEmailsTarget.value = '';
  }

  addRecipient(event: ActionEvent<{ stepCid: number }>): void {
    this.addRecipientToStep(event.params.stepCid.toString());
  }

  addRecipientToStep(stepCid: string, name = '', email = ''): void {
    const template = this.newRecipientRowTemplateTarget.content.firstElementChild;
    const newRecipientRow = template.cloneNode(true);
    const tbody = this.recipientsContainerTarget;
    tbody.appendChild(newRecipientRow);

    this.updateInputs(stepCid, { email, name });

    if (this.recipientInputTargets.length > 1) {
      this.removeRecipientButtonTargets.forEach((button) => { show(button); });
    } else {
      hide(this.removeRecipientButtonTargets[0]);
    }
  }

  updateInputs(
    stepCid: string,
    { email, name }: { email: string; name: string },
  ): void {
    const tbody = this.recipientsContainerTarget;
    const inputs = findEls(tbody.lastElementChild, 'input', '.wb-input-field');

    inputs[0].value = name;
    inputs[1].value = email;

    const titleId = `recipient-adder-title-label-${stepCid}`;
    const emailId = `recipient-adder-email-label-${stepCid}`;
    inputs[0].setAttribute('aria-labelledby', titleId);
    inputs[1].setAttribute('aria-labelledby', emailId);

    inputs[0].addEventListener('blur', this.blur);
    inputs[1].addEventListener('blur', this.blur);
  }

  blur(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.value = target.value.trim();
  }

  removeRecipient(event: DOMEvent): void {
    event.preventDefault();

    event.currentTarget.closest('tr').remove();

    if (this.recipientInputTargets.length === 1) {
      hide(this.removeRecipientButtonTargets[0]);
    }
  }

  async selectApproverList(event: FormInputEvent): Promise<void> {
    const approverListId = event.currentTarget.value;
    let recipients: Recipient[] = [];

    if (approverListId) {
      const url = `/api/approver_lists/${approverListId}/recipients`;

      recipients = await fetchGet(url)
        .then(({ data }: { data: Recipient[] }) => { return data; })
        .catch(() => { return []; });
    }

    this.updateRecipientList(recipients);
  }

  updateRecipientList(recipients: Recipient[]): void {
    const numApproversText = pluralizeWithCount(recipients.length, 'approver');
    this.numApproversTextTarget.textContent = numApproversText;

    this.approverListContainerTarget.innerHTML = '';

    if (recipients.length > 0) {
      recipients.forEach((recipient) => {
        const row = document.createElement('tr');
        const nameTd = document.createElement('td');
        nameTd.textContent = recipient.name;

        const emailTd = document.createElement('td');
        emailTd.textContent = recipient.email;

        row.appendChild(nameTd);
        row.appendChild(emailTd);

        this.approverListContainerTarget.appendChild(row);
      });
      show(this.approverListRecipientsTarget);
    } else {
      hide(this.approverListRecipientsTarget);
    }
  }

  changeStepType(event: ChangeStepTypeActionEvent): void {
    const stepCid = event.params.stepCid.toString();
    this.changeStepTypeForStep(stepCid, event.params.clientType);
  }

  changeStepTypeForStep(stepCid: string, clientType: ClientStepType): void {
    this.clearRecipients(clientType);

    this.changeStepTypeSection(stepCid, clientType);

    this.setHiddenTypeInput(clientType);
  }

  changeStepTypeSection(stepCid: string, clientType: ClientStepType): void {
    if (clientType === 'RecipientStep') {
      hide(this.approverListAdderSectionTarget);
      show(this.recipientAdderSectionTarget);
      this.addRecipientToStep(stepCid);
    } else if (clientType === 'ApproverListStep') {
      hide(this.recipientAdderSectionTarget);
      show(this.approverListAdderSectionTarget);
    } else {
      hide(this.approverListAdderSectionTarget);
      hide(this.recipientAdderSectionTarget);
    }
  }

  clearRecipients(clientType: ClientStepType): void {
    this.recipientInputTargets.forEach((row) => { row.remove(); });

    const requireApproverList = clientType === 'ApproverListStep';
    this.clearApproverListRecipients(requireApproverList);
  }

  clearApproverListRecipients(requireApproverList: boolean): void {
    hide(this.approverListRecipientsTarget);

    if (this.approverListSelectTarget.value) {
      this.approverListSelectTarget.value = '';
    }

    this.approverListSelectTarget.required = requireApproverList;
  }

  setHiddenTypeInput(clientType: ClientStepType): void {
    const input = this.hiddenStepTypeInputTarget;

    input.value = clientType === 'PromptStep' ? 'PromptStep' : 'RecipientStep';
  }
}

export default StepRowController;
