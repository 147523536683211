// @ts-strict-ignore
import { FrameElement } from '@hotwired/turbo';

import { triggerEvent } from 'src/helpers/event';

function loadDialog(url: string): void {
  const frame =
    document.querySelector<FrameElement>('turbo-frame#dialog-container');

  triggerEvent(frame, 'showLoader');
  frame.src = url;
}

export default {
  alert(message: string): void {
    window.alert(message);
  },

  confirm(message: string): boolean {
    return window.confirm(message);
  },
};

export { loadDialog };
