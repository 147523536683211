import { Controller } from '@hotwired/stimulus';

import { RemoveFileEvent } from './attachment_field_controller';

import { closestEl, findEl } from 'src/helpers/finders';

type EventParams = {
  blobId: string;
  fieldId: string;
};

class AttachmentFieldMirrorController extends Controller {
  removeAttachmentFieldFile(event: ActionEvent<EventParams>): void {
    const detail = { blobId: event.params.blobId, fieldId: event.params.fieldId };
    this.dispatch('mirrorFileRemoved', { detail });
    this.removeFileRow(event.currentTarget);
  }

  handleFieldFileRemoved({ detail: { fieldId, blobId } }: RemoveFileEvent): void {
    const containerSelector = `#field-${fieldId}-attachments-mirror`;
    const container = findEl(this.element, 'div', containerSelector);
    const buttonSelector =
      `[data-attachment-field-mirror-blob-id-param="${blobId}"]`;
    const button = findEl(container, 'button', buttonSelector);
    this.removeFileRow(button);
  }

  removeSavedFile(event: ActionEvent<EventParams>): void {
    const detail = { blobId: event.params.blobId, fieldId: event.params.fieldId };
    this.dispatch('mirrorSavedFileRemoved', { detail });
    closestEl(event.currentTarget, 'tr', '').remove();
  }

  handleSavedFieldFileRemoved(
    { detail: { fieldId, blobId } }: RemoveFileEvent,
  ): void {
    const selector = `[data-attachment-field-mirror-field-id-param="${fieldId}"]` +
      `[data-attachment-field-mirror-blob-id-param="${blobId}"]`;
    const button = findEl(this.element, 'button', selector);
    closestEl(button, 'tr', '').remove();
  }

  private removeFileRow(element: HTMLElement): void {
    const fileRow = closestEl(element, 'div', '.file-row');

    fileRow.remove();
  }
}

export default AttachmentFieldMirrorController;
