import { bindAll } from 'lodash';
import React, { ReactNode } from 'react';

import { paramsFromUrl } from '../helpers/query_params';

import Icon from 'src/common/components/icon';

type Props = {
  formGroup: FormGroup;
  userFormGroupDescriptions: UserFormGroupDescriptions;
};

type State = {
  searchTerm: string | undefined;
};

class ResponsesSearchPaginate extends React.Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    bindAll(this, 'clearIcon', 'updateState');

    const currentParams = paramsFromUrl(new URL(window.location.href));
    const searchTerm = currentParams.searchTerm || '';
    this.state = { searchTerm };
  }

  render(): ReactNode {
    const {
      formGroup,
      userFormGroupDescriptions,
    } = this.props;
    const { searchTerm } = this.state;
    const hasSearchTerm = Boolean(searchTerm);

    return (
      <div
        className='responses-search'
        data-responses-selection-target='searchToolbar'
      >
        <div className=' responses-search__search'>
          <div className='input-group input-group-search'>
            <span className='input-group-addon responses-search__input-addon'>
              <Icon name='search' size='1-5x' />
            </span>
            <input
              ref={this.inputRef}
              className='form-control'
              data-action={this.searchInputActionList()}
              data-responses-selection-target='searchInput'
              placeholder={`Search in ${userFormGroupDescriptions[formGroup]}`}
              type='search'
              value={searchTerm}
              onChange={this.updateState}
            />
            {hasSearchTerm && this.clearIcon()}
          </div>
        </div>
        <div
          className='hidden-sm hidden-xs responses-search__paginator'
          id='search-paginator'
        >
        </div>
      </div>
    );
  }

  clearIcon(): React.JSX.Element {
    return (
      <span className='input-group-addon responses-search__input-addon'>
        <button
          aria-label='Clear'
          className='link-text link-no-underline'
          data-action='responses-index#clearSearch'
          type='button'
          onClick={() => { this.setState({ searchTerm: '' }); }}
        >
          <Icon name='times' />
        </button>
      </span>
    );
  }

  updateState(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ searchTerm: event.target.value });
  }

  searchInputActionList(): string {
    return [
      'keyup->responses-selection#toggleFiltersEnabled',
      'keyup->responses-index#handleSearch',
    ].join(' ');
  }
}

export default ResponsesSearchPaginate;
export type { Props };
