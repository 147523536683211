import EditableFieldsCollection from 'src/chux/editable_fields/collection';

function getFields(): FieldsByNumber {
  return EditableFieldsCollection.getState();
}

function updateFields(payload: Partial<Field> | Partial<Field>[]): void {
  const attrs = Array.isArray(payload) ? payload : [payload];

  EditableFieldsCollection.set(attrs, { add: false, remove: false });
}

type BoundGetFields = typeof getFields;
type BoundUpdateFields = typeof updateFields;

export { getFields, updateFields };
export type { BoundGetFields, BoundUpdateFields };
