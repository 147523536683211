// @ts-strict-ignore
import uniq from 'lodash/uniq';
import Toposort from 'toposort-class';

import { FORMULA_FIELD_REGEX } from 'src/constants';

function detectCycle(fields: FieldsByNumber): string {
  const formulaVariablesFromLoop = detectFormulaLoop(fields);

  if (!formulaVariablesFromLoop) { return null; }

  const message = 'Loop detected - check dependencies: ' +
    `${formulaVariablesFromLoop.join(', ')}.`;

  return message;
}

// private

function detectFormulaLoop(fields: FieldsByNumber): string[] | null {
  const allFormulas = getAllFormulaFields(fields);
  // array of formula dependencies like [['f1', 'f2'], ['f1', 'f3'], ...]
  const edges = buildGraphEdgesFromFormulas(allFormulas);

  return edges.length > 0 && findCycle(edges);
}

function getAllFormulaFields(fields: FieldsByNumber): Field[] {
  return Object.values(fields).filter((field) => {
    return field.type === 'Formula';
  });
}

type Edge = [string, string];

function buildGraphEdgesFromFormulas(allFormulas): Edge[] {
  const edges: Edge[] = [];

  allFormulas.forEach((formulaField) => {
    const dependencies = parseDependencies(formulaField);
    const formulaVariable = `f${formulaField.number}`;

    dependencies.forEach((dependencyVariable) => {
      edges.push([formulaVariable, dependencyVariable]);
    });
  });
  return edges;
}

function findCycle(edges: Edge[]): string[] | null {
  const tsort = new Toposort();

  edges.forEach((edge) => {
    tsort.add(edge[0], edge[1]);
  });

  try {
    tsort.sort();
    return null;
  } catch (error) {
    return uniq(error.message.match(FORMULA_FIELD_REGEX));
  }
}

function parseDependencies(formulaField: Field): RegExpMatchArray | [] {
  return formulaField.content.toLowerCase().match(FORMULA_FIELD_REGEX) || [];
}

export default detectCycle;
