// @ts-strict-ignore
import { Json } from 'src/helpers/json';

function generateFilteredFormSetData(
  campaignId: number,
  queryParams: Partial<ResponsesQueryParams>,
): Json {
  const { searchTerm, sortBy, direction, formGroup } = queryParams;
  const filters = searchTerm ? {} : queryParams.filters;

  return {
    formSet: { campaignId, direction, filters, formGroup, searchTerm, sortBy },
    formSetType: 'filtered',
  };
}

function generateSelectedFormSetData(
  campaignId: number,
  formIds: string[],
  queryParams: Partial<ResponsesQueryParams>,
): Json {
  const { formGroup } = queryParams;

  return {
    formSet: { campaignId, formGroup, formIds },
    formSetType: 'selected',
  };
}

export { generateFilteredFormSetData, generateSelectedFormSetData };
