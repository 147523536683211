// @ts-strict-ignore
import 'jquery.signaturepad';

import A11yDialog from 'a11y-dialog';
import bindAll from 'lodash/bindAll';

import { assertInstanceOf } from 'src/helpers/assertion';
import Dialog from 'src/helpers/dialog';
import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';
import { removeAllTooltips } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';
import { hide } from 'src/helpers/visibility';

type SignaturePad = {
  clearCanvas: () => void;
  regenerate: (paths: string) => void;
};


class Signature {
  $addSig: JQuery;
  $element: JQuery;
  element: HTMLElement;
  $modal: JQuery;
  getSavedSignature: Function;
  updateSavedSignature: Function;
  signatureDialog: A11yDialog;
  signaturePad: SignaturePad;

  constructor(
    element: HTMLElement,
    { getSavedSignature, updateSavedSignature }:
    { getSavedSignature: Function; updateSavedSignature: Function },
  ) {
    this.$element = $(element);
    this.element = element;

    this.$addSig = $(this.addSig);
    this.$modal = $(this.modal);
    this.getSavedSignature = getSavedSignature;
    this.updateSavedSignature = updateSavedSignature;

    this.signatureDialog = new A11yDialog(this.$modal[0]);

    bindAll(
      this,
      'saveAndClose',
      'unsetSignature',
      'clearSavedSignature',
      'closeModalOnEnter',
      'saveSignature',
      'closeModal',
      'populateWithSignature',
      'saveSignatureData',
      'quickSign',
      'addOrPromptForSignature',
      'setSaveSignatureCheckbox',
    );

    this.$element.find('.signature-btn').on('click', this.addOrPromptForSignature);
    this.$element.find('.save_sig').on('click', this.saveAndClose);
    this.$element.find('.remove_sig').on('click', this.unsetSignature);
    this.$element.find('.clear-signature').on('click', this.clearSavedSignature);
    this.$modal.find('input').on('keyup', this.closeModalOnEnter);
    this.signatureDialog.on('show', () => {
      this.setSaveSignatureCheckbox();
    });
    this.setOptions();

    if (this.$modal.find('.name').val()) {
      this.saveSignature();
    }
  }

  get $sigPad(): JQuery {
    return this.$element.find('.sigPad');
  }

  get addSig(): HTMLElement {
    return findEl(this.element, 'a', '.add-sig');
  }

  get modal(): HTMLElement {
    return findEl(this.element, 'div', '.signature-modal');
  }

  get clearSignatureButton(): HTMLElement {
    return findEl(this.element, 'button', '.clear-signature');
  }

  setSaveSignatureCheckbox(): void {
    const signature = this.$modal.find('.signature-pad-name').val();
    const currentUserSignature = this.$modal.find('.current_user_signature');

    if (signature && currentUserSignature) {
      if (signature === currentUserSignature.val()) {
        this.$modal.find('.save_signature').prop('checked', true);
      }
    }
  }

  setOptions(): void {
    const signature = valToString(this.$element.find('.output').val());
    const sigPadOpts = { validateFields: false };

    this.signaturePad = this.$sigPad.signaturePad(sigPadOpts);

    if (signature) {
      this.signaturePad.regenerate($.parseJSON(signature));
    }
  }

  addOrPromptForSignature(): void {
    if (this.hasSavedSignature()) {
      this.quickSign();
    } else {
      this.signatureDialog.show();
    }
  }

  hasSavedSignature(): boolean {
    return Boolean(this.getSavedSignature().name);
  }

  quickSign(): void {
    this.populateWithSignature(this.$element);
    this.saveSignature();
  }

  saveSignature(): boolean {
    const output = this.$modal.find('.output').val();
    let name = valToString(this.$modal.find('.signature-pad-name').val());

    if (name) { name = name.trim(); } else {
      Dialog.alert(
        'Please type your name first. / Por favor teclee su nombre primero.',
      );
      return false;
    }

    this.$addSig.hide();

    // remove tooltip if appropriate
    removeAllTooltips();

    const $completedSig = $template('signature_complete_template', {
      href: this.$addSig.attr('href'),
      name,
      output,
    });

    this.$element.find('.completed-signature').empty().append($completedSig);

    const saveSigCheck = this.$modal.find('.save_signature').get(0);

    if (!saveSigCheck || !assertInstanceOf(saveSigCheck, HTMLInputElement)) {
      return true;
    }

    if (saveSigCheck.checked) {
      this.saveSignatureData(name, output);
    }

    return true;
  }

  clearSavedSignature(): void {
    this.unsetSignature();
    findEl(document, 'input', '#save_signature_name').value = '';
    findEl(document, 'input', '#save_signature_hash').value = '';
    findEl(document, 'input', '#clear_saved_signature').value = 'true';
    this.signaturePad.clearCanvas();

    findEl(this.element, 'input', '.save_signature').checked = false;
    hide(this.clearSignatureButton);

    this.updateSavedSignature({});
  }

  saveSignatureData(name: string, output: string[] | number | string): void {
    $('#save_signature_name').val(name);
    $('#save_signature_hash').val(output);
    $('#clear_saved_signature').val('false');

    this.updateSavedSignature({ hash: output, name });
  }

  populateWithSignature(signatureDiv: JQuery): void {
    const savedSignature = this.getSavedSignature();

    const $modal = $(signatureDiv).find('.signature-modal');
    const { name } = savedSignature;
    const output = savedSignature.hash || '';

    $modal.find('.signature-pad-name').val(name);
    $modal.find('.typed').text(name);
    $modal.find('.output').val(output);
  }

  unsetSignature(): void {
    this.$addSig.show();
    this.$element.find('.completed-signature').empty();
    this.$element.find('.name, .output').val('');
  }

  closeModalOnEnter(event: JQuery.KeyUpEvent): void {
    // iOS likes to keep the keyboard open on 'Go' press ... so remove
    //   focus to close it
    if (event.key === 'Enter') { this.saveAndClose(); }
  }

  closeModal(): void {
    this.signatureDialog.hide();
  }

  saveAndClose(): void {
    if (this.saveSignature()) {
      this.closeModal();
    }
  }
}

export default Signature;
