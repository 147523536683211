// @ts-strict-ignore
import { InferThunkActionCreatorType } from 'react-redux';

function removeFlash(id: number) {
  return { payload: id, type: 'flashes/REMOVE' };
}

export { removeFlash };

export type RemoveFlash = InferThunkActionCreatorType<typeof removeFlash>;
