// @ts-strict-ignore
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import { snakeCaseKeys } from 'src/helpers/json';

function urlFromParams(queryParams: ResponsesQueryParams, baseUrl: string): URL {
  const url = new URL(baseUrl);
  url.searchParams.set('direction', queryParams.direction);
  url.searchParams.set('page', queryParams.page.toString());
  url.searchParams.set('page_size', queryParams.pageSize.toString());
  url.searchParams.set('sort_by', queryParams.sortBy);
  url.searchParams
    .set('filters', JSON.stringify(snakeCaseKeys(queryParams.filters)));
  url.searchParams.set('form_group', queryParams.formGroup);

  if (queryParams.searchTerm) {
    url.searchParams.set('search_term', queryParams.searchTerm);
  }

  return url;
}

function paramsFromUrl(url: URL): ResponsesQueryParams {
  const filters = JSON.parse(decodeURI(url.searchParams.get('filters')));
  const camelizedFilters =
    mapKeys(filters, (_value, key) => { return camelCase(key); });
  const queryParams: ResponsesQueryParams = {
    direction: url.searchParams.get('direction') as SortDirection,
    filters: camelizedFilters,
    formGroup: url.searchParams.get('form_group') as FormGroup,
    page: parseInt(url.searchParams.get('page'), 10),
    pageSize: parseInt(url.searchParams.get('page_size'), 10),
    sortBy: url.searchParams.get('sort_by'),
  };

  const searchTerm = url.searchParams.get('search_term');
  if (searchTerm) { queryParams.searchTerm = searchTerm; }

  return queryParams;
}

function isFilterApplied(filters): boolean {
  return Object.keys(filters).some((key) => { return key !== 'statusGroup'; });
}

export { isFilterApplied, paramsFromUrl, urlFromParams };
