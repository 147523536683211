// @ts-strict-ignore
import Backbone from 'backbone';
import keyBy from 'lodash/keyBy';

import FieldModel from 'src/doc_editor/fields/models/field';
import FieldModels from 'src/doc_editor/fields/models/index';

type FetchFields = {
  docId: number;
  opts: {
    cache?: boolean | null;
    success?: Callback;
    url?: string;
  };
};

type CollectionType = {
  fetchFields: (payload: FetchFields) => void;
  getState: () => FieldsByNumber;
  get: (number: number) => FieldModel;
  set: (attrs: Partial<Field>[]) => void;
  models: FieldModel[];
  add: (attrs: Partial<Field>[]) => FieldModel[];
  modelAttrs: () => Field[];
  remove: (number: number) => void;
  reset: () => void;
  fetch: () => void;
};

const COLLECTION_EVENTS = 'change add remove reset';

class EditableFieldsCollectionClass extends Backbone.Collection<FieldModel> {
  cache = null;

  fetchFields({ opts, docId }: FetchFields) {
    opts.cache = false;
    opts.url = `/docs/${docId}/fields`;

    this.fetch(opts);
  }

  getState(): FieldsByNumber {
    this.cache ||= keyBy(this.modelAttrs(), 'number');

    return this.cache;
  }

  initialize() {
    this.on(COLLECTION_EVENTS, () => { this.cache = null; });
  }

  model = (attrs: Partial<Field>): FieldModel => {
    return new FieldModels[attrs.type]({ ...attrs });
  };

  modelAttrs() {
    return this.map((model: FieldModel) => {
      const viewAttrs = model.toJSON();

      return {
        ...viewAttrs,
        isValid: model.isValid(),
        label: viewAttrs.label,
      };
    });
  }

  modelId(attrs: Partial<Field>) { return attrs.number; }

  parse({ data }) { return data; }

  updateAll(attrs: Partial<Field>) {
    this.models.forEach((model) => { model.set(attrs); });
  }
}

const EditableFieldsCollection = new EditableFieldsCollectionClass();

export default EditableFieldsCollection;
export type { CollectionType };
